<template>
  <b-modal header-class="ion-bg-modal-header" size="xl"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ $t('terms.termsTitle') }} {{ $t('common.edit') }}</div>
    </template>

    <div class="row">
      <div class="col-xl-12">

        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="terms-update-title">
              <span>{{ $t('terms.title') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="terms-update-title"
                          v-model="form.title"
                          :class="{ 'is-invalid': submitted && $v.form.title.$error }" />
            <div v-if="submitted && $v.form.title.$error" class="invalid-feedback">
              <span v-if="!$v.form.title.required">{{ $t('validation.titleIsRequired') }}</span>
            </div>
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="terms-update-publish">
              <span>{{ $t('terms.isPublish') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <div class="btn-group w-100" role="group">
              <input type="radio" class="btn-check" id="isPublishBtn1" autocomplete="off"
                     v-model="form.isPublish" :value="true" />
              <label class="btn btn-outline-secondary" for="isPublishBtn1">{{ $t('common.open') }}</label>
              <input type="radio" class="btn-check" id="isPublishBtn2" autocomplete="off"
                     v-model="form.isPublish" :value="false" />
              <label class="btn btn-outline-secondary" for="isPublishBtn2">{{ $t('common.private') }}</label>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-xl-4">
            <label class="form-label" for="terms-update-system-type">
              <span>{{ $t('terms.appCsmsType') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <multiselect id="terms-update-system-type"
                         v-model="form.system"
                         label="text" track-by="value"
                         :options="systemTypeOptions"
                         selectLabel=""
                         selectedLabel=""
                         deselectLabel=""
                         :searchable="false" :allow-empty="false" />
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="terms-update-agree">
              <span>{{ $t('terms.agreeOption') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <multiselect id="terms-update-agree"
                         v-model="form.agree"
                         label="text" track-by="value"
                         :options="agreeTypeOptions"
                         selectLabel=""
                         selectedLabel=""
                         deselectLabel=""
                         :searchable="false" :allow-empty="false" />
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="terms-update-type">
              <span>{{ $t('terms.termType') }}</span>
            </label>
            <multiselect id="terms-update-type"
                         v-model="form.usedType"
                         label="text" track-by="value"
                         :options="usedTypeOptions"
                         selectLabel=""
                         selectedLabel=""
                         deselectLabel=""
                         :searchable="false" :allow-empty="false" />
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-xl-12">
            <label class="form-label" for="terms-update-contents">{{ $t('terms.contents') }}</label>
            <ToastViewer v-if="!$_utils.isEmpty(form.contents)"
                         id="terms-update-contents" ref="toastUiEditor" :contents="form.contents" />
          </div>
        </div>

      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-end">
        <b-button variant="secondary" class="w-xs p-1 me-3" @click="close">{{ $t('btn.close') }}</b-button>
        <b-button variant="success" class="w-xs p-1" @click="save">{{ $t('btn.save') }}</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';
import { required } from 'vuelidate/lib/validators';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import ToastViewer from '@/views/components/ToastViewer.vue';

export default {
  props: {
    selectedId: {
      type: Number
    }
  },
  components: {
    Multiselect, ToastViewer
  },
  data() {
    return {
      visible: false,
      submitted: false,
      form: {
        title: null,
        contents: null,
        isPublish: false,
        system: { text: 'APP', value: 'APP' },
        agree: { text: i18n.t('common.essential'), value: 'Required' },
        usedType: { text: i18n.t('common.no'), value: null }
      },
      systemTypeOptions: [
        { text: 'APP', value: 'APP' },
        { text: 'CSMS', value: 'CSMS' },
      ],
      agreeTypeOptions: [
        { text: i18n.t('common.essential'), value: 'Required' },
        { text: i18n.t('common.choice'), value: 'Optional' }
      ],
      usedTypeOptions: [
        { text: i18n.t('common.no'), value: null },
        { text: i18n.t('terms.typeMember'), value: 'Member' },
        { text: i18n.t('terms.typeCard'), value: 'Card' },
        { text: i18n.t('terms.typePlan'), value: 'Plan' },
        { text: i18n.t('terms.typeQuit'), value: 'Quit' }
      ],
    }
  },
  validations: {
    form: {
      title: { required }
    }
  },
  methods: {
    show() {
      const vm = this;

      vm.getItem();
      vm.visible = true;
    },
    getItem() {
      const vm = this;
      const _url = `/onmapi/view/TermsPolicy/retrieve/${this.selectedId}`;

      http.get(_url).then((res) => {
        vm.item = res.data.item;
        vm.form = Object.assign({}, vm.item);

        if (res.data.item.appCsmsType) {
          vm.form = { ...vm.form, system: vm.systemTypeOptions.find(ele => ele.value === res.data.item.appCsmsType) };
        }

        if (res.data.item.agreeOption) {
          vm.form = { ...vm.form, agree: vm.agreeTypeOptions.find(ele => ele.value === res.data.item.agreeOption) };
        }

        if (res.data.item.termType) {
          vm.form = { ...vm.form, usedType: vm.usedTypeOptions.find(ele => ele.value === res.data.item.termType) };
        }
      }).catch((err) => {
        console.error('Terms Retrieve Error !!', err)
      });
    },
    resetForm() {
      const vm = this;

      vm.form = {
        title: null,
        contents: null,
        isPublish: false,
        system: { text: 'APP', value: 'APP' },
        agree: { text: i18n.t('common.essential'), value: 'Required' },
        usedType: { text: i18n.t('common.no'), value: null }
      };

      vm.$nextTick(() => {
        this.$v.$reset();
      });
    },
    close() {
      const vm = this;

      vm.resetForm();
      this.$emit('callback');
      vm.visible = false;
    },
    save() {
      const vm = this;
      vm.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        Swal.fire({
          title: i18n.t('alert.confirmEdit'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            vm.saveItems();
          }
        });
      }
    },
    saveItems() {
      const vm = this;
      const _url = '/onmapi/view/TermsPolicy/update';
      const _html = this.$refs.toastUiEditor.getHTML();

      let _form = {
        id: this.selectedId,
        title: vm.form.title,
        termKind: vm.form.title,
        contents: _html,
        isPublish: vm.form.isPublish,
        appCsmsType: vm.form.system.value,
        agreeOption: vm.form.agree.value,
        termType: vm.form.usedType.value
      };

      http.patch(_url, _form).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.edited'),
            confirmButtonText: i18n.t('btn.close')
          });
          vm.close();
        }
      }).catch(err => {
        console.error('Terms Update Error :: ', err)

        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.error'),
          confirmButtonText: i18n.t('btn.close')
        });
      });
    }
  }
}
</script>

<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>
